<template>
    <rateModal v-if="this.$store.state.showRate" />
    <div class="row">
        <div class="col-md-12" v-if="OrderInfo">
          <div class="title-nav">
            <h2>{{ $t("Service_details") }}</h2>
            <div class="d-flex align-items-center">
              <Show_Price
                v-if="OrderInfo?.status?.id == 5"
                :price_offers="OrderInfo?.price_offers"
              />
            </div>
          </div>
            <div class="item d-flex justify-content-between">
                <div class="title">{{ OrderInfo.title }}</div>
                <div style="font-size: 20px;color: #225476;font-weight: bold;">#{{ orderNum }}</div>
            </div>
        </div>
        <div class="col-md-6" v-if="OrderInfo?.attributes?.length > 0" v-for="(item, index) in OrderInfo?.attributes"
            :key="index">
            <div class="item">
                <div class="title">{{ item.name }}</div>
                <div class="value" v-if="item.type == 'text' || item.type == 'date'">{{
        attribute_values[index].attribute_value }}</div>
                <div class="value" v-if="item.type == 'select'">
                    {{ SelectValue(item, index) }}
                </div>
                <div class="value" v-if="item.type == 'checkbox'">{{ SelectValue(item, index) }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
    data() {
        return {
        };
    },
    props: {
        OrderInfo: Object,
        attribute_values: Array,
        step: String ,
        orderNum: Number
    },
    methods: {
        SelectValue(atrr, index) {
            if (atrr.type == 'select') {
                let valueSelect = '';
                atrr.attribute_values.forEach(element => {
                    if (element.id == this.attribute_values[index].attribute_value_id) {
                        valueSelect = element.value;
                    }
                });
                return valueSelect;
            }
            if (atrr.type == 'checkbox') {
                let valueCheckbox = '';
                atrr.attribute_values.forEach(element => {
                    if (this.attribute_values[index].attribute_value_id.includes(element.id)) {
                        valueCheckbox.push(element.value);
                    }
                });
                return valueCheckbox;
            }


        }
    },

    components: {
        rateModal: defineAsyncComponent(() => import('@/components/Services/Order/Tabs/RateModal.vue')),
    }

}
</script>
